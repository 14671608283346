import request from '@/service/request'

/* 论文 删除接口
* id
* */
export const delThesisApi = (id) => {
    return request.post(`/teacher/teacherTermPaperResult/del/${id}`)
}

/* 论文 获取接口
* id
* */
export const getThesisByIdApi = (id) => {
    return request.post(`/teacher/teacherTermPaperResult/getById/${id}`)
  }

/* 论文  page接口
* */
export const pageThesisApi = (params) => {
  return request.post('/teacher/teacherTermPaperResult/page', params)
}

/* 论文  统计接口
* */
export const countThesisApi = (params) => {
  return request.post('/teacher/teacherTermPaperResult/count', params)
}

/* 论文  当前用户page接口
* */
export const pageThesisByCurUserApi = (params) => {
  return request.post('/teacher/teacherTermPaperResult/pageCurrentUser', params)
}

/* 论文 保存接口 */
export const saveThesisApi = (params) => {
  return request.post('/teacher/teacherTermPaperResult/save', params)
}
