<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>班级汇总:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增班级汇总
            </el-button>
        </div>
        <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="className" min-width="120" label="所带班级"></el-table-column>
                <el-table-column prop="classNum" min-width="120" label="人数"></el-table-column>
                <el-table-column prop="attendanceRate" min-width="120" label="本学期出勤率">
                    <template v-slot="{ row }">
                        {{ row.attendanceRate ? `${row.attendanceRate}%` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="certPassRate" min-width="150" label="本学期考证通过率">
                    <template v-slot="{ row }">
                        {{ row.certPassRate ? `${row.certPassRate}%` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="meetName" min-width="120" label="主题班会名称"></el-table-column>
                <el-table-column prop="meetContent" min-width="120" label="班会内容"></el-table-column>
                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <div v-if="!isOnlyRead">

                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delClassCollectApi, row.id, `班级汇总-${row.activityName}`)">
                                删除
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </table-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '班级汇总详情' : '新增/编辑班级汇总:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存班级汇总
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='所带班级' prop='className'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.className' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='人数' prop='classNum'>
                        <el-input-number :disabled="isOnlyRead" v-model="formData.classNum" @change="handleclassNumChange"
                            :min="0" :max="300"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='本学期出勤率' prop='attendanceRate'>
                        <el-input-number :disabled="isOnlyRead" v-model="formData.attendanceRate"
                            @change="handleAttendanceRateChange" :min="0" :max="100"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='本学期考证通过率' prop='certPassRate'>
                        <el-input-number :disabled="isOnlyRead" v-model="formData.certPassRate"
                            @change="handleCertPassRateChange" :min="0" :max="100"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='主题班会名称' prop='meetName'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.meetName' />
                    </el-form-item>
                </el-col>
                <el-col :span='24'>
                    <el-form-item label='班会内容' prop='meetContent'>
                        <el-input :disabled="isOnlyRead" maxlength="255" v-model.trim='formData.meetContent' type="textarea"
                            :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-班级汇总', save())">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { saveClassCollectByCurUserApi, getClassCollectByIdApi, pageClassCollectApi, delClassCollectApi } from '@/api/infoCollect/personCollect-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            gradeList: {},
            termList: {},
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                className: '',
                classNum: 0,
                attendanceRate: 0,
                certPassRate: 0,
                meetName: '',
                meetContent: ''
            },
            formDataClone: {},
            tableData: [],
            rules: {
                className: [this.$store.state.commonValidate.limit20WordsObj],
                classNum: [this.$store.state.commonValidate.validatorNumberObj],
                attendanceRate: [this.$store.state.commonValidate.validatorNumberObj],
                certPassRate: [this.$store.state.commonValidate.validatorNumberObj],
                meetName: [this.$store.state.commonValidate.limit20WordsObj]
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delClassCollectApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageClassCollectApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        handleclassNumChange(value) {
            this.formData.classNum = value
        },
        handleAttendanceRateChange(value) {
            this.formData.attendanceRate = value
        },
        handleCertPassRateChange(value) {
            this.formData.certPassRate = value
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getClassCollectByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }

            this.formData.term = this.term
            this.formData.gradeName = this.gradeName

            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveClassCollectByCurUserApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
