<template>
    <div class="collect-form ">
        <el-divider content-position="left">
            <h3>外出公干:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增外出公干
            </el-button>
        </div>
        <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="goOutDate" min-width="120" label="外出日期"></el-table-column>
                <el-table-column prop="goOutDay" min-width="120" label="外出时间(天)"></el-table-column>
                <el-table-column show-overflow-tooltip prop="goOutAddress" min-width="150" label="外出地点"></el-table-column>
                <el-table-column show-overflow-tooltip prop="goOutReason" min-width="150" label="外出原因"></el-table-column>
                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <div v-if="!isOnlyRead">
                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delGoOutWorkApi, row.id, `外出公干-${row.goOutDate}-${row.goOutAddress}`)">
                                删除
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </table-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '外出公干详情' : '新增/编辑外出公干:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存外出公干
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='外出日期' prop='goOutDate'>
                        <el-date-picker :disabled="isOnlyRead" type='date' placeholder='外出日期' v-model='formData.goOutDate'
                            style='width: 100%' value-format='yyyy-MM-dd' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='外出时间(天)' prop='goOutDay'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.goOutDay' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='外出地点' prop='goOutAddress'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.goOutAddress' />
                    </el-form-item>
                </el-col>
                <el-col :span='24'>
                    <el-form-item label='外出原因' prop='goOutReason'>
                        <el-input :disabled="isOnlyRead" maxlength="255" v-model.trim='formData.goOutReason' type="textarea"
                            :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-外出公干', save())">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { saveGoOutWorkByCurUserApi, getGoOutWorkByIdApi, pageGoOutWorkApi, delGoOutWorkApi } from '@/api/infoCollect/personCollect-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            gradeList: {},
            termList: {},
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                goOutDate: '',
                goOutDay: '',
                goOutAddress: '',
                goOutReason: ''

            },
            formDataClone: {},
            tableData: [],
            rules: {
                goOutAddress: [this.$store.state.commonValidate.limit30WordsObj],
                goOutDay: [this.$store.state.commonValidate.validatorNumberObj]
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delGoOutWorkApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageGoOutWorkApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getGoOutWorkByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }
            this.formData.term = this.term
            this.formData.gradeName = this.gradeName

            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveGoOutWorkByCurUserApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
