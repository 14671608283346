import request from '@/service/request'

/* 课题 删除接口
* id
* */
export const delSubjectApi = (id) => {
    return request.post(`/teacher/teacherTermProjectResult/del/${id}`)
}

/* 课题 获取接口
* id
* */
export const getSubjectByIdApi = (id) => {
    return request.post(`/teacher/teacherTermProjectResult/getById/${id}`)
  }

/* 课题  page接口
* */
export const pageSubjectApi = (params) => {
  return request.post('/teacher/teacherTermProjectResult/page', params)
}

/* 课题  统计接口
* */
export const countSubjectApi = (params) => {
  return request.post('/teacher/teacherTermProjectResult/count', params)
}

/* 课题  page当前用户接口
* */
export const pageSubjectByCurUseApi = (params) => {
  return request.post('/teacher/teacherTermProjectResult/pageCurrentUser', params)
}

/* 课题 保存接口 */
export const saveSubjectApi = (params) => {
  return request.post('/teacher/teacherTermProjectResult/save', params)
}

/* 课题 保存当前用户接口 */
export const saveSubjectByCurUserApi = (params) => {
  return request.post('/teacher/teacherTermProjectResult/saveCurrentUser', params)
}
