<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>著作:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增著作
            </el-button>
        </div>
        <tbale-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="workName" min-width="120" label="著作名称"></el-table-column>
                <el-table-column prop="workCategory" min-width="120" label="著作类别"></el-table-column>
                <el-table-column prop="academicDiscipline" min-width="100" label="学科领域"></el-table-column>
                <el-table-column prop="publisherName" min-width="120" label="出版社名称"></el-table-column>
                <el-table-column prop="publishDate" min-width="120" label="出版日期"></el-table-column>
                <el-table-column prop="authorRole" label="本人角色"></el-table-column>
                <el-table-column prop="wordCount" label="总字数"></el-table-column>
                <el-table-column prop="selfWordCount" min-width="120" label="本人撰写字数"></el-table-column>
                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <div v-if="!isOnlyRead">
                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delworkResultApi, row.id, `著作-${row.workName}`)">
                                删除
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </tbale-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '著作详情' : '新增/编辑著作:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存著作
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='著作名称' prop='workName'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.workName' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='出版社名称' prop='publisherName'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.publisherName' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='学科领域' prop='academicDiscipline'>
                        <el-select :disabled="isOnlyRead" v-model="formData.academicDiscipline" placeholder="请选择">
                            <el-option v-for=" item in academicDisciplineList" :key="item.type" :label="item.type"
                                :value="item.type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='著作类别' prop='workCategory'>
                        <el-select :disabled="isOnlyRead" v-model="formData.workCategory" placeholder="请选择">
                            <el-option v-for=" item in workCategoryList" :key="item.type" :label="item.type"
                                :value="item.type">
                            </el-option>
                        </el-select>

                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='出版日期' prop='publishDate'>
                        <el-date-picker :disabled="isOnlyRead" type='date' placeholder='出版日期' v-model='formData.publishDate'
                            style='width: 100%' value-format='yyyy-MM-dd' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='本人角色' prop='authorRole'>
                        <el-select :disabled="isOnlyRead" v-model="formData.authorRole" placeholder="请选择">
                            <el-option v-for=" item in authorRoleList" :key="item.type" :label="item.type"
                                :value="item.type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='总字数' prop='wordCount'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.wordCount' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='本人撰写字数' prop='selfWordCount'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.selfWordCount' />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-著作', save())">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { authorRoleList, academicDisciplineList, workCategoryList } from './options.js'

import { saveworkResultByCurUserApi, getworkResultByIdApi, pageworkResultApi, delworkResultApi } from '@/api/infoCollect/personCollect-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            gradeList: {},
            termList: {},
            authorRoleList,
            academicDisciplineList,
            workCategoryList,
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                workName: '',
                workCategory: '',
                academicDiscipline: '',
                publisherName: '',
                publishDate: '',
                authorRole: '',
                wordCount: '',
                selfWordCount: ''

            },
            tableData: [],
            rules: {
                workName: [this.$store.state.commonValidate.limit20WordsObj, { required: true, message: '请输入著作名称', trigger: 'blur' }],
                publisherName: [this.$store.state.commonValidate.limit20WordsObj],
                wordCount: [this.$store.state.commonValidate.validatorNumberObj],
                selfWordCount: [this.$store.state.commonValidate.validatorNumberObj]
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delworkResultApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageworkResultApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getworkResultByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }
            this.formData.term = this.term
            this.formData.gradeName = this.gradeName

            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveworkResultByCurUserApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
