<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>专利:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增专利
            </el-button>
        </div>
        <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="patentName" label="专利名"></el-table-column>
                <el-table-column prop="patentNo" label="专利号"></el-table-column>
                <el-table-column prop="applyDate" min-width="100px" label="申请时间"></el-table-column>
                <el-table-column prop="grantDate" min-width="100px" label="授权时间"></el-table-column>

                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <div v-if="!isOnlyRead">
                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delPatentApi, row.id, `专利-${row.patentName}`)">
                                删除
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </table-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '专利详情' : '新增/编辑专利:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save()" type="primary" size="small">
                保存专利
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='申请时间' prop='applyDate'>
                        <el-date-picker :disabled="isOnlyRead" type='date' placeholder='申请时间' v-model='formData.applyDate'
                            style='width: 100%' value-format='yyyy-MM-dd' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='授权时间' prop='grantDate'>
                        <el-date-picker :disabled="isOnlyRead" type='date' placeholder='授权时间' v-model='formData.grantDate'
                            style='width: 100%' value-format='yyyy-MM-dd' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='专利名' prop='patentName'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.patentName' />
                    </el-form-item>

                </el-col>
                <el-col :span='12'>
                    <el-form-item label='专利号' prop='patentNo'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.patentNo' />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-专利', savePatentApi)">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { savePatentApi, getPatentByIdApi, pagePatentApi, delPatentApi } from '@/api/achievement/patent-api.js'

export default {
    name: 'formPatent',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            formData: {
                id: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                term: '',
                patentName: '',
                applyDate: '',
                grantDate: '',
                patentNo: ''
            },
            tableData: [],
            rules: {
                patentName: [this.$store.state.commonValidate.limit30WordsObj, { required: true, message: '请输入专利名', trigger: 'blur' }],
                patentNo: [this.$store.state.commonValidate.limit30WordsObj, { required: true, message: '专利号不能为空' }]
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delPatentApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pagePatentApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getPatentByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }
            this.formData.term = this.term
            this.formData.gradeName = this.gradeName
            this.$refs.form.validate((valid) => {
                if (valid) {
                    savePatentApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
