<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>论文:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增论文
            </el-button>
        </div>
        <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="paperTitle" label="论文题目"></el-table-column>
                <el-table-column prop="publishTitle" label="发表刊物名称" min-width="120"></el-table-column>
                <el-table-column prop="publishTime" label="发表时间" min-width="100"></el-table-column>
                <el-table-column prop="publishIssue" label="发表期号"></el-table-column>
                <el-table-column prop="publishVolume" label="发表卷号"></el-table-column>
                <el-table-column prop="startPage" label="起始页码"></el-table-column>
                <el-table-column prop="endPage" label="结束页码"></el-table-column>
                <el-table-column prop="authorRole" label="本人角色"></el-table-column>
                <el-table-column prop="paperInclusionStatus" label="论文收录情况" min-width="120"></el-table-column>
                <el-table-column prop="journalRank" label="发表刊物级别" min-width="120"></el-table-column>
                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <div v-if="!isOnlyRead">
                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delThesisApi, row.id, `专利-${row.paperTitle}`)">
                                删除
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </table-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '论文详情' : '新增/编辑论文:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存论文
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='论文题目' prop='paperTitle'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.paperTitle' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='本人角色' prop='authorRole'>
                        <el-select :disabled="isOnlyRead" v-model="formData.authorRole" placeholder="请选择">
                            <el-option v-for=" item in authorRoleList" :key="item.type" :label="item.type"
                                :value="item.type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='论文收录情况' prop='paperInclusionStatus'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.paperInclusionStatus' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='发表刊物名称' prop='publishTitle'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.publishTitle' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='发表刊物级别' prop='journalRank'>
                        <el-select :disabled="isOnlyRead" v-model="formData.journalRank" placeholder="请选择">
                            <el-option v-for=" item in journalRankList" :key="item.type" :label="item.type"
                                :value="item.type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='发表时间' prop='publishTime'>
                        <el-date-picker :disabled="isOnlyRead" type='date' placeholder='发表时间' v-model='formData.publishTime'
                            style='width: 100%' value-format='yyyy-MM-dd' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='发表期号' prop='publishIssue'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.publishIssue' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='发表卷号' prop='publishVolume'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.publishVolume' />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label='起始页码' prop='startPage'>
                        <el-input maxlength="10" :disabled="isOnlyRead" v-model.trim='formData.startPage' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='结束页码' prop='endPage'>
                        <el-input maxlength="10" :disabled="isOnlyRead" v-model.trim='formData.endPage' />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-论文', saveThesisApi)">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { saveThesisApi, getThesisByIdApi, pageThesisApi, delThesisApi } from '@/api/achievement/thesis-api.js'
import { journalRankList, authorRoleList } from './options.js'

export default {
    name: 'formThesis',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            journalRankList, // 发表刊物级别列表
            authorRoleList, // 本人角色列表
            isEditing: false,
            formData: {
                id: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                paperTitle: '',
                patentName: '',
                publishTitle: '',
                publishTime: '',
                publishIssue: '',
                publishVolume: '',
                startPage: '',
                endPage: '',
                authorRole: '',
                paperInclusionStatus: '',
                journalRank: ''
            },
            gradeList: {},
            termList: {},
            tableData: [],
            rules: {
                paperTitle: [this.$store.state.commonValidate.limit30WordsObj, { required: true, message: '请输入论文题目', trigger: 'blur' }],
                paperInclusionStatus: [this.$store.state.commonValidate.limit30WordsObj],
                publishIssue: [this.$store.state.commonValidate.limit30WordsObj],
                publishVolume: [this.$store.state.commonValidate.limit30WordsObj],
                publishTitle: [this.$store.state.commonValidate.limit30WordsObj],
                startPage: [this.$store.state.commonValidate.validatorNumberObj],
                endPage: [this.$store.state.commonValidate.validatorNumberObj],
                journalRank: [{ required: true, message: '请选择发表刊物级别', trigger: 'change' }],
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delThesisApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageThesisApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getThesisByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }
            this.formData.term = this.term
            this.formData.gradeName = this.gradeName

            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveThesisApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
