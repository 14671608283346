<template>
    <div class="form-tem-info collect-form">
        <el-form ref='form' :model='formData' :rules='rules' label-width='170px' style=" padding: 0px;">
            <form-term-time v-if="teacherTermTimeRecordVisiable" :gradeName="gradeName" :term="term"
                :isOnlyRead="isOnlyRead" :teacherTermTimeRecord="formData.teacherTermTimeRecord" />
            <el-divider content-position="left">
                <tool-tips-group tips-content="该模块为必填！">
                    <h3>本学期汇报:</h3>
                </tool-tips-group>
            </el-divider>
            <div class="header-button mb-1">
                <el-button v-if="!isOnlyRead" @click="handleSave" type="primary" size="small">
                    保存本学期汇报
                </el-button>
            </div>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='主要工作成绩' prop='workResult'>
                        <el-input maxlength="3000" :disabled="isOnlyRead" v-model.trim='formData.workResult' type="textarea"
                            :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='自我诊断意见' prop='selfOpinion'>
                        <el-input maxlength="3000" :disabled="isOnlyRead" v-model.trim='formData.selfOpinion'
                            type="textarea" :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='改进措施及成效' prop='improveWayResult'>
                        <el-input maxlength="3000" :disabled="isOnlyRead" v-model.trim='formData.improveWayResult'
                            type="textarea" :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='(学生)突发事件处理记录' prop='stuEmergencyRecord'>
                        <el-input maxlength="3000" :disabled="isOnlyRead" v-model.trim='formData.stuEmergencyRecord'
                            type="textarea" :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='个人获奖情况' prop='awards'>
                        <el-input maxlength="3000" :disabled="isOnlyRead" v-model.trim='formData.awards' type="textarea"
                            :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='下学期工作计划' prop='nextTermPlan'>
                        <el-input maxlength="3000" :disabled="isOnlyRead" v-model.trim='formData.nextTermPlan'
                            type="textarea" :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer" />
    </div>
</template>

<script>
import { personCollectList } from './options'
import { savePersonCollectApi, getPersonCollectByIdApi } from '@/api/infoCollect/personCollect-api.js'
import dialog from '@/vue/mixins/dialog'

import formTermTime from './formTermTime.vue'

export default {
    name: 'formTermInfo',
    mixins: [dialog],
    props: {
        parentId: String,
        term: String,
        gradeName: String,
        isOnlyRead: Boolean
    },
    components: {
        formTermTime
    },
    data() {
        return {
            personCollectList,
            nowPersonCollectList: '',
            formData: {
                id: '',
                gradeName: '',
                term: '',
                workResult: '',
                selfOpinion: '',
                improveWayResult: '',
                stuEmergencyRecord: '',
                teacherTermTimeRecord: {

                },
                awards: '',
                nextTermPlan: ''
            },
            teacherTermTimeRecordVisiable: false,
            gradeList: {},
            termList: {},
            rules: {
            }
        }
    },
    mounted() {
        this.$nextTick(async () => {
            if (this.parentId) {
                await this.getDataById(this.parentId, getPersonCollectByIdApi)
            }
            this.teacherTermTimeRecordVisiable = true
        })
    },
    methods: {
        async handleSave(flag) {
            const checkFlag = this.checkData()

            if (!checkFlag) {
                this.$confirm('请先选择学年、学期!!', '提示')
            } else {
                this.formData.term = this.term
                this.formData.gradeName = this.gradeName
                await this.save('教学信息-本学期汇报', savePersonCollectApi)
            }
        },
        checkData() {
            if ((!!this.term && !!this.gradeName) === false) {
                return false
            } else {
                return true
            }
        }
    }
}
</script>
<style lang="scss" >
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
