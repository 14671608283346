import request from '@/service/request'
/** *************************教学信息采集 PersonCollect****************************/

/* 教学信息采集 page接口
* */
export const pagePersonCollectApi = (params) => {
    return request.post('/teacher/teacherTermWorkRecord/page', params)
}

/* 教学信息采集 page接口当前用户(简略x√)
* */
export const pagePersonCollecDescCurrentUserApi = (params) => {
    return request.post('teacher/teacherTermWorkRecord/pageDescCurrentUser', params)
}

/* 教学信息采集 page接口(统计-简略x√)
* */
export const pagePersonCollecApi = (params) => {
    return request.post('teacher/teacherTermWorkRecord/pageCollectCount', params)
}

/* 教学信息采集 保存接口
* */
export const savePersonCollectApi = (params) => {
    return request.post('/teacher/teacherTermWorkRecord/save', params)
}

/* 教学信息采集 删除接口
* */
export const delPersonCollectByIdApi = (id) => {
    return request.post(`/teacher/teacherTermWorkRecord/del/${id}`)
}

/* 教学信息采集 getById接口
* */
export const getPersonCollectByIdApi = (id) => {
    return request.post(`/teacher/teacherTermWorkRecord/getById/${id}`)
}

/** *************************教育教学 CourseRecord****************************/
/* 教育教学  page接口(当前用户)
* */
export const pageCourseRecordByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermCourseRecord/pageCurrentUser', params)
}

/* 教育教学  page接口
* */
export const pageCourseRecordApi = (params) => {
    return request.post('/teacher/teacherTermCourseRecord/page', params)
}

/* 教育教学 保存接口(当前用户)  */
export const saveCourseRecordByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermCourseRecord/saveCurrentUser', params)
}

/* 教育教学 删除接口
* id
* */
export const delCourseRecordApi = (id) => {
    return request.post(`/teacher/teacherTermCourseRecord/del/${id}`)
}

/* 教育教学 获取接口
* id
* */
export const getCourseRecordByIdApi = (id) => {
    return request.post(`/teacher/teacherTermCourseRecord/getById/${id}`)
}

/** *************************稿件 Article****************************/
/* 稿件  page接口(当前用户)
* */
export const pageArticleByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermArticleRecord/pageCurrentUser', params)
}

/* 稿件  page接口
* */
export const pageArticleApi = (params) => {
    return request.post('/teacher/teacherTermArticleRecord/page', params)
}

/* 稿件 保存接口(当前用户)  */
export const saveArticleByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermArticleRecord/saveCurrentUser', params)
}

/* 稿件 删除接口
* id
* */
export const delArticleApi = (id) => {
    return request.post(`/teacher/teacherTermArticleRecord/del/${id}`)
}

/* 稿件 获取接口
* id
* */
export const getArticleByIdApi = (id) => {
    return request.post(`/teacher/teacherTermArticleRecord/getById/${id}`)
}

/** *************************著作 workResult****************************/
/* 著作  page接口
* */
export const pageworkResultApi = (params) => {
    return request.post('/teacher/teacherTermWorkResult/page', params)
}
/* 著作  page接口(当前用户)
* */
export const pageworkResultByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermWorkResult/pageCurrentUser', params)
}

/* 著作 保存接口(当前用户)  */
export const saveworkResultByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermWorkResult/saveCurrentUser', params)
}

/* 著作 删除接口
* id
* */
export const delworkResultApi = (id) => {
    return request.post(`/teacher/teacherTermWorkResult/del/${id}`)
}

/* 著作 获取接口
* id
* */
export const getworkResultByIdApi = (id) => {
    return request.post(`/teacher/teacherTermWorkResult/getById/${id}`)
}
/** *************************社会实践 socialPractice****************************/
/* 社会实践  page接口(当前用户)
* */
export const pageSocialPracticeByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermSocialPracticeTrain/pageCurrentUser', params)
}

/* 社会实践  page接口
* */
export const pageSocialPracticeApi = (params) => {
    return request.post('/teacher/teacherTermSocialPracticeTrain/page', params)
}

/* 社会实践 保存接口(当前用户)  */
export const saveSocialPracticeByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermSocialPracticeTrain/saveCurrentUser', params)
}

/* 社会实践 删除接口
* id
* */
export const delSocialPracticeApi = (id) => {
    return request.post(`/teacher/teacherTermSocialPracticeTrain/del/${id}`)
}

/* 社会实践 获取接口
* id
* */
export const getSocialPracticeByIdApi = (id) => {
    return request.post(`/teacher/teacherTermSocialPracticeTrain/getById/${id}`)
}
/** *************************社会服务 socialServe****************************/
/* 社会服务  page接口(当前用户)
* */
export const pageSocialServeByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermSocialServiceProvided/pageCurrentUser', params)
}

/* 社会服务  page接口
* */
export const pageSocialServeApi = (params) => {
    return request.post('/teacher/teacherTermSocialServiceProvided/page', params)
}

/* 社会服务 保存接口(当前用户)  */
export const saveSocialServeByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermSocialServiceProvided/saveCurrentUser', params)
}

/* 社会服务 删除接口
* id
* */
export const delSocialServeApi = (id) => {
    return request.post(`/teacher/teacherTermSocialServiceProvided/del/${id}`)
}

/* 社会服务 获取接口
* id
* */
export const getSocialServeByIdApi = (id) => {
    return request.post(`/teacher/teacherTermSocialServiceProvided/getById/${id}`)
}

/** *************************应届毕业生顶岗服务 GraduateInternship****************************/
/* 应届毕业生顶岗服务  page接口(当前用户)
* */
export const pageGraduateInternshipByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermSupervisedGraduateInternship/pageCurrentUser', params)
}

/* 应届毕业生顶岗服务  page接口
* */
export const pageGraduateInternshipApi = (params) => {
    return request.post('/teacher/teacherTermSupervisedGraduateInternship/page', params)
}

/* 应届毕业生顶岗服务 保存接口(当前用户)  */
export const saveGraduateInternshipByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermSupervisedGraduateInternship/saveCurrentUser', params)
}

/* 应届毕业生顶岗服务 删除接口
* id
* */
export const delGraduateInternshipApi = (id) => {
    return request.post(`/teacher/teacherTermSupervisedGraduateInternship/del/${id}`)
}

/* 应届毕业生顶岗服务 获取接口
* id
* */
export const getGraduateInternshipByIdApi = (id) => {
    return request.post(`/teacher/teacherTermSupervisedGraduateInternship/getById/${id}`)
}
/** *************************学生活动 StudentActivity****************************/
/* 学生活动  page接口(当前用户)
* */
export const pageStudentActivityByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermStudentActivityParticipation/pageCurrentUser', params)
}

/* 学生活动  page接口(当前用户)
* */
export const pageStudentActivityApi = (params) => {
    return request.post('/teacher/teacherTermStudentActivityParticipation/page', params)
}

/* 学生活动 保存接口(当前用户)  */
export const saveStudentActivityByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermStudentActivityParticipation/saveCurrentUser', params)
}

/* 学生活动 删除接口
* id
* */
export const delStudentActivityApi = (id) => {
    return request.post(`/teacher/teacherTermStudentActivityParticipation/del/${id}`)
}

/* 学生活动 获取接口
* id
* */
export const getStudentActivityByIdApi = (id) => {
    return request.post(`/teacher/teacherTermStudentActivityParticipation/getById/${id}`)
}
/** *************************班级汇总 ClassCollect****************************/
/* 班级汇总  page接口(当前用户)
* */
export const pageClassCollectByCurUserApi = (params) => {
    return request.post('/teacher/teacherClassCollectSituation/pageCurrentUser', params)
}

/* 班级汇总  page接口
* */
export const pageClassCollectApi = (params) => {
    return request.post('/teacher/teacherClassCollectSituation/page', params)
}

/* 班级汇总 保存接口(当前用户)  */
export const saveClassCollectByCurUserApi = (params) => {
    return request.post('/teacher/teacherClassCollectSituation/saveCurrentUser', params)
}

/* 班级汇总 删除接口
* id
* */
export const delClassCollectApi = (id) => {
    return request.post(`/teacher/teacherClassCollectSituation/del/${id}`)
}

/* 班级汇总 获取接口
* id
* */
export const getClassCollectByIdApi = (id) => {
    return request.post(`/teacher/teacherClassCollectSituation/getById/${id}`)
}
/** *************************欠费汇总 ClassArrear****************************/
/* 欠费汇总  page接口(当前用户)
* */
export const pageClassArrearByCurUserApi = (params) => {
    return request.post('/teacher/teacherClassArrearSituation/pageCurrentUser', params)
}

/* 欠费汇总  page接口
* */
export const pageClassArrearApi = (params) => {
    return request.post('/teacher/teacherClassArrearSituation/page', params)
}

/* 欠费汇总 保存接口(当前用户)  */
export const saveClassArrearByCurUserApi = (params) => {
    return request.post('/teacher/teacherClassArrearSituation/saveCurrentUser', params)
}

/* 欠费汇总 删除接口
* id
* */
export const delClassArrearApi = (id) => {
    return request.post(`/teacher/teacherClassArrearSituation/del/${id}`)
}

/* 欠费汇总 获取接口
* id
* */
export const getClassArrearByIdApi = (id) => {
    return request.post(`/teacher/teacherClassArrearSituation/getById/${id}`)
}
/** *************************外出公干 GoOutWork****************************/
/* 外出公干  page接口(当前用户)
* */
export const pageGoOutWorkByCurUserApi = (params) => {
    return request.post('/teacher/teacherGoOutWork/pageCurrentUser', params)
}

/* 外出公干  page接口
* */
export const pageGoOutWorkApi = (params) => {
    return request.post('/teacher/teacherGoOutWork/page', params)
}

/* 外出公干 保存接口(当前用户)  */
export const saveGoOutWorkByCurUserApi = (params) => {
    return request.post('/teacher/teacherGoOutWork/saveCurrentUser', params)
}

/* 外出公干 删除接口
* id
* */
export const delGoOutWorkApi = (id) => {
    return request.post(`/teacher/teacherGoOutWork/del/${id}`)
}

/* 外出公干 获取接口
* id
* */
export const getGoOutWorkByIdApi = (id) => {
    return request.post(`/teacher/teacherGoOutWork/getById/${id}`)
}
/** *************************考勤 TermTime****************************/
/* 考勤 保存接口(当前用户)  */
export const saveTermTimeByCurUserApi = (params) => {
    return request.post('/teacher/teacherTermTimeRecord/saveCurrentUser', params)
}

/* 考勤 获取接口(getById)
* id
* */
export const getTermTimeByIdApi = (id) => {
    return request.post(`/teacher/teacherTermTimeRecord/getById/${id}`)
}

/* 考勤 获取接口(curUser,term,gradeName)
* id
* */
export const getTermTimeByCurrentAndTerm = (params) => {
    return request.post('/teacher/teacherTermTimeRecord/getByCurrentAndTerm', params)
}
