<template>
    <div class="form-tem-info collect-form">
        <el-form ref='form' :model='formData' :rules='rules' label-width='170px' style=" padding: 0px;">
            <el-divider content-position="left">
                <h3>考勤:</h3>
            </el-divider>
            <div class="header-button mb-1">
                <el-button v-if="!isOnlyRead" @click="handleSave('termTime')" type="primary" size="small">
                    保存考勤
                </el-button>
            </div>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='出勤天数' prop='workDayNum'>
                        <el-input maxlength="3" :disabled="isOnlyRead" v-model.trim='formData.workDayNum' />
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='旷工天数' prop='missDayNum'>
                        <el-input maxlength="3" :disabled="isOnlyRead" v-model.trim='formData.missDayNum' />
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='请假天数' prop='leaveDayNum'>
                        <el-input maxlength="3" :disabled="isOnlyRead" v-model.trim='formData.leaveDayNum' />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer" />
    </div>
</template>

<script>
// import { getGradeList } from '@/api/grade.js'
// import { getParamsList } from '@/api/sysParams.js'
import { personCollectList } from './options'
import { saveTermTimeByCurUserApi, getTermTimeByIdApi } from '@/api/infoCollect/personCollect-api.js'
import dialog from '@/vue/mixins/dialog'

export default {
    name: 'formTermInfo',
    mixins: [dialog],
    props: {
        teacherTermTimeRecord: Object,
        term: String,
        gradeName: String,
        isOnlyRead: Boolean
    },
    data() {
        return {
            personCollectList,
            nowPersonCollectList: '',
            teacherType: '',
            formData: {
                id: '',
                gradeName: '',
                term: '',
                workDayNum: '',
                missDayNum: '',
                leaveDayNum: ''

            },
            gradeList: {},
            termList: {},
            rules: {
                workDayNum: [this.$store.state.commonValidate.validatorNumberObj],
                missDayNum: [this.$store.state.commonValidate.validatorNumberObj],
                leaveDayNum: [this.$store.state.commonValidate.validatorNumberObj]
            }
        }
    },
    mounted() {
        this.$nextTick(async () => {
            if (this.teacherTermTimeRecord?.id) {
                await this.getDataById(this.teacherTermTimeRecord.id, getTermTimeByIdApi)
            }
        })
    },
    methods: {
        async handleSave() {
            const checkFlag = this.checkData()

            if (!checkFlag) {
                this.$confirm('请先选择学年、学期!!', '提示')
            } else {
                this.formData.gradeName = this.gradeName
                this.formData.term = this.term
                await this.save('教学信息-考勤', saveTermTimeByCurUserApi)
            }
        },
        checkData() {
            if ((!!this.term && !!this.gradeName) === false) {
                return false
            } else {
                return true
            }
        }

    }
}
</script>
<style lang="scss" >
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
