<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>顶岗实习:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增顶岗实习
            </el-button>
        </div>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column type='index' label='ID' width='50' />
            <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term}学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="guideClass" min-width="120" label="指导班级"></el-table-column>
            <el-table-column prop="studentCount" min-width="120" label="学生人数"></el-table-column>
            <el-table-column label="操作" min-width="150" fixed="right">
                <template v-slot="{ row }">
                    <div v-if="!isOnlyRead">
                        <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                            编辑
                        </el-button>
                        <el-button type="text" :disabled="isEditing"
                            @click="del(delGraduateInternshipApi, row.id, `顶岗实习-${row.guideClass}`)">
                            删除
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button type="text" @click="showEdit(row.id)">
                            查看详情
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '顶岗实习详情' : '新增/编辑顶岗实习:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存顶岗实习
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='指导班级' prop='guideClass'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.guideClass' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='学生人数' prop='studentCount'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.studentCount' />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-顶岗实习', save())">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { saveGraduateInternshipByCurUserApi, getGraduateInternshipByIdApi, pageGraduateInternshipApi, delGraduateInternshipApi } from '@/api/infoCollect/personCollect-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            gradeList: {},
            termList: {},
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                guideClass: '',
                studentCount: ''

            },
            tableData: [],
            rules: {
                guideClass: [this.$store.state.commonValidate.limit20WordsObj, { required: true, message: '请输入指导班级', trigger: 'blur' }],
                studentCount: [this.$store.state.commonValidate.validatorNumberObj]
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delGraduateInternshipApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageGraduateInternshipApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getGraduateInternshipByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }

            this.formData.term = this.term
            this.formData.gradeName = this.gradeName

            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveGraduateInternshipByCurUserApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
