import request from '@/service/request'

/* 专利 删除接口
* id
* */
export const delPatentApi = (id) => {
    return request.post(`/teacher/teacherTermPatent/del/${id}`)
}

/* 专利 获取接口
* id
* */
export const getPatentByIdApi = (id) => {
    return request.post(`/teacher/teacherTermPatent/getById/${id}`)
  }

/* 专利  page接口
* */
export const pagePatentApi = (params) => {
  return request.post('/teacher/teacherTermPatent/page', params)
}
/* 专利  统计接口
* */
export const countPatentApi = (params) => {
  return request.post('/teacher/teacherTermPatent/count', params)
}

/* 专利  page接口当前用户
* */
export const pagePatentByCurUserApi = (params) => {
  return request.post('/teacher/teacherTermPatent/pageCurrentUser', params)
}

/* 专利 保存接口 */
export const savePatentApi = (params) => {
  return request.post('/teacher/teacherTermPatent/save', params)
}
