import request from '@/service/request'

/* 个人信息采集 获取接口（userId）
* id
* */
export const getInfoCollectByUserId = (id) => {
    return request.post(`/teacher/teacherInfo/getByUserId/${id}`)
}

/* 个人信息采集 保存接口
* */
export const saveInfoCollect = (params) => {
    return request.post('/teacher/teacherInfo/save', params)
}
