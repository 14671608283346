<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>稿件:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增稿件
            </el-button>
        </div>
        <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="articleTitle" min-width="120" label="稿件题目"></el-table-column>
                <el-table-column prop="phblishWhere" min-width="120" label="发表于何处"></el-table-column>
                <el-table-column prop="publishTime" min-width="100" label="发表时间"></el-table-column>
                <el-table-column prop="articleWordNum" min-width="120" label="稿件字数"></el-table-column>
                <el-table-column prop="articleType" label="稿件类型"></el-table-column>
                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <div v-if="!isOnlyRead">
                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delArticleApi, row.id, `稿件-${row.articleTitle}`)">
                                删除
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </table-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '稿件详情' : '新增/编辑稿件:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存稿件
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='稿件类型' prop='articleType'>
                        <el-radio-group v-if="!isOnlyRead" v-model="formData.articleType">
                            <el-radio-button v-for="{ type } in articleTypeList" :key="type" :label="type">{{ type
                            }}</el-radio-button>
                        </el-radio-group>
                        <div v-else>{{ formData.articleType }}</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='稿件题目' prop='articleTitle'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.articleTitle' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='发表于何处' prop='phblishWhere'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.phblishWhere' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='发表时间' prop='publishTime'>
                        <el-date-picker :disabled="isOnlyRead" type='date' placeholder='开始时间' v-model='formData.publishTime'
                            style='width: 100%' value-format='yyyy-MM-dd' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='稿件字数' prop='articleWordNum'>
                        <el-input maxlength="10" :disabled="isOnlyRead" v-model.trim='formData.articleWordNum' />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-稿件', save())">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { articleTypeList } from './options.js'

import { saveArticleByCurUserApi, getArticleByIdApi, delArticleApi, pageArticleApi } from '@/api/infoCollect/personCollect-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            gradeList: {},
            termList: {},
            articleTypeList,
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                articleTitle: '',
                phblishWhere: '',
                publishTime: '',
                articleWordNum: '',
                articleType: '新闻稿'

            },
            formDataClone: {},
            tableData: [],
            rules: {
                articleTitle: [this.$store.state.commonValidate.limit20WordsObj, { required: true, message: '请输入稿件题目', trigger: 'blur' }],
                phblishWhere: [this.$store.state.commonValidate.limit20WordsObj],
                articleWordNum: [this.$store.state.commonValidate.validatorNumberObj]
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delArticleApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageArticleApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getArticleByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }
            this.formData.term = this.term
            this.formData.gradeName = this.gradeName
            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveArticleByCurUserApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
