// 人员采集内容
export const personCollectList = [
    // 专任教师  辅导员 行政人员 中层领导人员
    { type: '教师', infoCollectOption: ['本学期汇报', '教育教学', '稿件', '课题', '论文', '著作', '专利', '社会实践', '社会服务', '毕业生顶岗实习', '竞赛', '学生活动'] },
    { type: '辅导员', infoCollectOption: ['本学期汇报', '教育教学', '稿件', '课题', '论文', '专利', '社会实践', '竞赛', '学生活动', '班级汇总', '欠费汇总'] },
    { type: '行政人员', infoCollectOption: ['本学期汇报', '教育教学', '稿件', '课题', '论文', '专利', '社会实践', '社会服务', '竞赛', '外出公干'] },
    { type: '中层干部', infoCollectOption: ['本学期汇报', '教育教学', '稿件', '课题', '论文', '专利', '社会实践', '社会服务', '竞赛', '学生活动', '外出公干'] }
]

// 发表刊物级别列表
export const journalRankList = [
    { type: '一般公开刊物' },
    { type: '省级' },
    { type: '国家级' },
    { type: '核心期刊' }
]
// 本人角色列表
export const authorRoleList = [
    { type: '独撰' },
    { type: '第一主编' },
    { type: '第二主编' },
    { type: '参编' }
]

// 课题类型
export const projectTypeList = [
    { type: '校级' },
    { type: '市级' },
    { type: '省级' },
    { type: '国家级' }
]
// 是否代表性成果
export const isRepresentativeAchievementFlag = [
    { type: 1, name: '是' },
    { type: 0, name: '否' }
]
// 是否横向课题
export const isHorizontalCourseTopicFlag = [
    { type: 1, name: '是' },
    { type: 0, name: '否' }
]

// 竞赛类别
export const contestCategoryList = [
    { type: '校级' },
    { type: '市级' },
    { type: '省级' },
    { type: '国家级' }
]
// 获奖类别
export const awardCategoryList = [
    { type: '一等奖' },
    { type: '二等奖' },
    { type: '三等奖' },
    { type: '其他奖' }
]

// 获奖类别
export const contestantTypeList = [
    { type: '学生' },
    { type: '教师' }

]

// 课程类别
export const courseTypeList = [
    { type: '专业核心课' },
    { type: '专业基础课' },
    { type: '公开课' }
]

// 稿件类别
export const articleTypeList = [
    { type: '新闻稿' },
    { type: '专题报道' },
    { type: '其他' }
]

// 学科领域
export const academicDisciplineList = [
    { type: '信息技术类' },
    { type: '工程技术类' },
    { type: '电子商务类' },
    { type: '会计类' },
    { type: '语言文学类' },
    { type: '体育类' },
    { type: '工商管理类' },
    { type: '其他类' }
]

// 著作类别
export const workCategoryList = [
    { type: '教材' },
    { type: '专著' },
    { type: '教辅材料' },
    { type: '学生指导用书' }
]

// 培训类别
export const trainTypeList = [
    { type: '继续教育' },
    { type: '校内培训' },
    { type: '国内培训' },
    { type: '国外培训' }
]

// 人员类别
export const memberTypeList = [
    { type: '教师', name: '教师' },
    { type: '辅导员', name: '辅导员' },
    { type: '行政人员', name: '行政人员' },
    { type: '中层干部', name: '中层干部' }
  ]
