<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>教育教学:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增教育教学
            </el-button>
        </div>
        <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="courseName" min-width="100" label="课程名称"></el-table-column>
                <el-table-column prop="courseClass" label="任课班级"></el-table-column>
                <el-table-column prop="courseType" min-width="100" label="课程类别"></el-table-column>
                <el-table-column prop="courseTimeNum" min-width="120" label="课程教学课时数"></el-table-column>
                <el-table-column prop="filePath" show-overflow-tooltip min-width="200" label="课件上传"></el-table-column>
                <el-table-column prop="passRate" label="及格率">
                    <template v-slot="{ row }">
                        {{ row.passRate ? `${row.passRate}%` : '' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <template v-if="!isOnlyRead">
                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delCourseRecordApi, row.id, `教育教学-${row.courseName}`)">
                                删除
                            </el-button>
                        </template>
                        <template v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </table-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '教育教学详情' : '新增/编辑教育教学:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存教育教学
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='课程类别' prop='courseType'>
                        <el-radio-group v-if="!isOnlyRead" v-model="formData.courseType">
                            <el-radio-button :disabled="isOnlyRead" v-for=" { type }  in  courseTypeList " :key="type"
                                :label="type">{{ type
                                }}</el-radio-button>
                        </el-radio-group>
                        <div v-else>{{ formData.courseType }}</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='课程名称' prop='courseName'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.courseName' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='任课班级' prop='courseClass'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.courseClass' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='课程教学课时数' prop='courseTimeNum'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.courseTimeNum' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='及格率' prop='passRate'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.passRate' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='18'>
                    <el-form-item label='课件上传' prop='filePath'>
                        <uploader-zip-file v-if="!isOnlyRead" @update:url="updateUrl" :url="formData.filePath" />
                        <div v-else>{{ formData.filePath }} </div>
                        <el-button v-if="this.formData.filePath.length > 0" :disabled="downLoadZipFlag" type="text"
                            @click="downLoadZip(formData.filePath)">
                            点击下载课件
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer" />
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { courseTypeList } from './options.js'

import { saveCourseRecordByCurUserApi, getCourseRecordByIdApi, pageCourseRecordApi, delCourseRecordApi } from '@/api/infoCollect/personCollect-api.js'
import UploaderZipFile from '@/components/common/components/UploaderZipFile.vue'

export default {
    components: { UploaderZipFile },
    name: 'gradeDialog',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            gradeList: {},
            termList: {},
            courseTypeList,
            downLoadZipFlag: false,
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                courseName: '',
                courseClass: '',
                courseType: '专业核心课',
                courseTimeNum: '',
                passRate: '',
                filePath: ''

            },
            formDataClone: {},
            tableData: [],
            rules: {
                courseName: [this.$store.state.commonValidate.limit20WordsObj, { required: true, message: '请输入课程名称', trigger: 'blur' }],
                courseClass: [this.$store.state.commonValidate.limit20WordsObj],
                courseTimeNum: [this.$store.state.commonValidate.validatorNumberObj],
                passRate: [this.$store.state.commonValidate.validatorNumberObj]
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delCourseRecordApi,
        updateUrl(url) {
            this.formData.filePath = url
        },
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageCourseRecordApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        // 下载课件
        downLoadZip(filePath) {
            this.downLoadZipFlag = true
            const path = `${process.env.VUE_APP_IMG_URL}${filePath}`

            // window.open(path.replace(/\([^\)]*\)/g, ''))
            window.open(path)
            this.downLoadZipFlag = false
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getCourseRecordByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }

            this.formData.term = this.term
            this.formData.gradeName = this.gradeName

            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveCourseRecordByCurUserApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
