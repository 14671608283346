<template>
  <el-dialog class="grade-dialog" :title="`${preTitle}岗位职责`" :visible.sync="show" :close-on-click-modal="false"
             width="800px" :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="岗位职责" prop="jobDuty">
            <el-input :disabled="isOnlyRead" type="textarea" rows="3" v-model="formData.jobDuty"
                      placeholder=""></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer v-if="!isOnlyRead">
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('岗位职责', saveInfoCollect)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

import { saveInfoCollect, getInfoCollectByUserId } from '@/api/infoCollect/infoCollect-api.js'

export default {
  name: 'gradeDialog',
  mixins: [dialog],
  data() {
    return {
      id: '',
      formData: {
        id: '',
        userId: '',
        jobDuty: ''
      },
      gradeList: {},
      termList: {},
      rules: {
        jobDuty: [{
          required: true,
          message: '请填写岗位职责'
        }]
      }
    }
  },
  props: {
    isOnlyRead: Boolean
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.isOnlyRead === true) {
        this.formData.userId = this.id
        this.rules = {}
      } else {
        this.formData.userId = this.$store.state.login.userInfo.id
      }

      await this.getDataById(this.formData.userId, getInfoCollectByUserId)
    })
  },
  methods: {
    saveInfoCollect // 保存 api
  }
}
</script>
<style lang="scss">
.grade-dialog {
  .el-textarea,
  .el-input,
  .el-select {
    &.is-disabled {

      .el-textarea__inner,
      .el-input__inner {

        background-color: #fff !important;
        color: #606266 !important;
      }
    }
  }
}
</style>
