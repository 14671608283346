import request from '@/service/request'

/* 竞赛 删除接口
* id
* */
export const delCompetitionApi = (id) => {
    return request.post(`/teacher/teacherTermGuidedCompetitionAwards/del/${id}`)
}

/* 竞赛 获取接口
* id
* */
export const getCompetitionByIdApi = (id) => {
    return request.post(`/teacher/teacherTermGuidedCompetitionAwards/getById/${id}`)
}

/* 竞赛  page接口
* */
export const pageCompetitionApi = (params) => {
  return request.post('/teacher/teacherTermGuidedCompetitionAwards/page', params)
}

/* 竞赛  count接口
* */
export const countCompetitionApi = (params) => {
  return request.post('/teacher/teacherTermGuidedCompetitionAwards/count', params)
}

/* 竞赛  page接口当前用户
* */
export const pageCompetitionByCurUserApi = (params) => {
  return request.post('/teacher/teacherTermGuidedCompetitionAwards/pageCurrentUser', params)
}

/* 竞赛 保存接口 */
export const saveCompetitionApi = (params) => {
  return request.post('/teacher/teacherTermGuidedCompetitionAwards/save', params)
}
