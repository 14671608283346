<template>
    <div class="collect-form">
        <el-divider content-position="left">
            <h3>课题:</h3>
        </el-divider>
        <div class="header-button mb-1">
            <el-button v-if="!isOnlyRead" :disabled="isEditing" @click="showEdit()" type="primary" size="small">
                新增课题
            </el-button>
        </div>
        <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
            <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
                <el-table-column type='index' label='ID' width='50' />
                <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
                <el-table-column prop="term" label="学期">
                    <template v-slot="{ row }">
                        {{ row.term ? `第${row.term}学期` : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="projectNo" label="项目编号"></el-table-column>
                <el-table-column prop="projectName" label="项目名称"></el-table-column>
                <el-table-column prop="projectType" label="项目类别">
                    <template v-slot="{ row }">
                        <el-tag>{{ row.projectType }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="projectAmount" label="经费额度"></el-table-column>
                <el-table-column prop="beginMonth" min-width="100" label="开始日期"></el-table-column>
                <el-table-column prop="endMonth" min-width="100" label="结束日期"></el-table-column>
                <el-table-column prop="projectRole" label="本人角色"></el-table-column>
                <el-table-column prop="projectSelfRank" label="本人排名"></el-table-column>
                <el-table-column prop="representativeAchievementFlag" label="是否代表性成果" width="120">
                    <template v-slot="{ row }">
                        <el-tag v-if="Number(row.representativeAchievementFlag) === 1" type="success">是</el-tag>
                        <el-tag v-else type="danger">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="horizontalCourseTopicFlag" label="是否横向课题" width="110">
                    <template v-slot="{ row }">
                        <el-tag v-if="Number(row.horizontalCourseTopicFlag) === 1" type="success">是</el-tag>
                        <el-tag v-else type="danger">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="projectClient" label="项目委托单位" width="110"></el-table-column>
                <el-table-column label="操作" min-width="150" fixed="right">
                    <template v-slot="{ row }">
                        <div v-if="!isOnlyRead">
                            <el-button type="text" :disabled="isEditing" @click="showEdit(row.id)">
                                编辑
                            </el-button>
                            <el-button type="text" :disabled="isEditing"
                                @click="del(delSubjectApi, row.id, `课题-${row.projectName}`)">
                                删除
                            </el-button>
                        </div>
                        <div v-else>
                            <el-button type="text" @click="showEdit(row.id)">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </table-view>
        <el-divider v-if="isEditing" content-position="left">
            <h3>{{ isOnlyRead ? '课题详情' : '新增/编辑课题:' }}</h3>
        </el-divider>
        <div v-if="isEditing" class="header-button mb-1">
            <el-button v-if="!isOnlyRead" @click="save" type="primary" size="small">
                保存课题
            </el-button>
            <el-button v-if="!isOnlyRead" @click="closeEdit()" type="danger" size="small">
                取消新增/编辑
            </el-button>
        </div>
        <el-form v-if="isEditing" ref='form' :model='formData' :rules='rules' label-width='170px'>
            <el-row>
                <el-col :span='16'>
                    <el-form-item label='开始/结束' prop='beginMonth'>
                        <el-col :span='11'>
                            <el-date-picker :disabled="isOnlyRead" type='date' :picker-options="dateTimeStartFunc"
                                placeholder='开始时间' v-model='formData.beginMonth' style='width: 100%'
                                value-format='yyyy-MM-dd' />
                        </el-col>
                        <el-col class='line' style='text-align: center' :span='2'>-</el-col>
                        <el-col :span='11'>
                            <el-date-picker :disabled="isOnlyRead" type='date' :picker-options="dateTimeEndFunc"
                                placeholder='结束时间' v-model='formData.endMonth' style='width: 100%'
                                value-format='yyyy-MM-dd' />
                        </el-col>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='24'>
                    <el-form-item label='项目类别' prop='projectType'>
                        <el-radio-group v-if="!isOnlyRead" v-model="formData.projectType">
                            <el-radio-button v-for="{ type } in projectTypeList" :key="type" :label="type">{{ type
                            }}</el-radio-button>
                        </el-radio-group>
                        <div v-else>{{ formData.projectType }}</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='是否代表性成果' prop='representativeAchievementFlag'>
                        <el-radio-group v-if="!isOnlyRead" v-model="formData.representativeAchievementFlag">
                            <el-radio-button v-for="{ type, name } in isRepresentativeAchievementFlag" :key="type"
                                :label="type">{{ name
                                }}</el-radio-button>
                        </el-radio-group>
                        <div v-else>{{ formData.representativeAchievementFlag === 1 ? "是" : '否' }}</div>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='是否横向课题' prop='horizontalCourseTopicFlag'>
                        <el-radio-group v-if="!isOnlyRead" v-model="formData.horizontalCourseTopicFlag">
                            <el-radio-button v-for="{ type, name } in isHorizontalCourseTopicFlag" :key="type"
                                :label="type">{{ name
                                }}</el-radio-button>
                        </el-radio-group>
                        <div v-else>{{ formData.horizontalCourseTopicFlag === 1 ? "是" : '否' }}</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='本人角色' prop='projectRole'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.projectRole' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='本人排名' prop='projectSelfRank'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.projectSelfRank' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label='项目编号' prop='projectNo'>
                        <el-input maxlength="20" :disabled="isOnlyRead" v-model.trim='formData.projectNo' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='项目名称' prop='projectName'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.projectName' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='项目委托单位' prop='projectClient'>
                        <el-input :disabled="isOnlyRead" v-model.trim='formData.projectClient' />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='经费额度' prop='projectAmount'>
                        <el-input maxlength="10" :disabled="isOnlyRead" v-model.trim='formData.projectAmount' />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer">
            <!-- <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
            <el-button type='primary' @click="save('教学信息-论文', saveSubjectApi)">确 定</el-button> -->
        </div>
    </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { cloneDeep, isEqual } from 'lodash'

import { projectTypeList, isRepresentativeAchievementFlag, isHorizontalCourseTopicFlag } from './options.js'

import { saveSubjectApi, getSubjectByIdApi, pageSubjectApi, delSubjectApi } from '@/api/achievement/subject-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog, tableView],
    props: {
        term: String,
        gradeName: String,
        isOnlyRead: Boolean,
        CurUserId: String
    },
    data() {
        return {
            id: '',
            isEditing: false,
            gradeList: {},
            termList: {},
            projectTypeList,
            isRepresentativeAchievementFlag,
            isHorizontalCourseTopicFlag,
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptId: '',
                deptName: '',
                teacherNo: '',
                projectRole: '',
                projectSelfRank: '',
                gradeName: '',
                term: '',
                beginMonth: '', // 开始时间
                endMonth: '', // 结束时间
                projectNo: '',
                projectName: '',
                projectClient: '',
                projectAmount: '',
                remarks: '',
                projectType: '校级', // 项目类别
                horizontalCourseTopicFlag: 0, // 是否横向课题
                representativeAchievementFlag: 0 // 是否代表性成果
            },
            formDataClone: {},
            tableData: [],
            rules: {
                teacherNo: [this.$store.state.commonValidate.validatorNumberObj],
                projectRole: [this.$store.state.commonValidate.limit20WordsObj],
                projectSelfRank: [this.$store.state.commonValidate.limit20WordsObj],
                projectName: [this.$store.state.commonValidate.limit20WordsObj, { required: true, message: '请输入项目名称', trigger: 'blur' }],
                projectClient: [this.$store.state.commonValidate.limit20WordsObj],
                projectNo: [this.$store.state.commonValidate.limit20WordsObj, { required: true, message: '请输入项目编号', trigger: 'blur' }],
                projectAmount: [this.$store.state.commonValidate.validatorNumberObj],
                projectType: [{ required: true, message: '请选择项目类别', trigger: 'change' }],

            },
            dateTimeStartFunc: {
                disabledDate: time => {
                    if (this.formData.endMonth) {
                        return time.getTime() > new Date(this.formData.endMonth).getTime()
                    }
                }
            },
            dateTimeEndFunc: {
                disabledDate: time => {
                    if (this.formData.beginMonth) {
                        return time.getTime() < new Date(this.formData.beginMonth).getTime() - 8.64e7
                    }
                }
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.renderTable()
        })
        this.formDataClone = cloneDeep(this.formData)
    },
    methods: {
        delSubjectApi,
        renderTable() {
            this.queryInfo.term = this.term
            this.queryInfo.gradeName = this.gradeName

            if (this.isOnlyRead === true) {
                this.queryInfo.userId = this.CurUserId
            } else {
                this.queryInfo.userId = this.$store.state.login.userInfo.id
            }

            pageSubjectApi(this.queryInfo).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        showEdit(id) {
            this.isEditing = true

            if (id) {
                this.getDataById(id, getSubjectByIdApi)
            } else {
                this.setInitData()
            }
        },
        closeEdit() {
            this.isEditing = false
            this.clear()
        },
        save() {
            if (isEqual(this.formData, this.formDataClone)) {
                this.$message.error({ message: '表单内容没有改变，请填写表单!' })
                return
            }

            this.formData.term = this.term
            this.formData.gradeName = this.gradeName

            this.$refs.form.validate((valid) => {
                if (valid) {
                    saveSubjectApi(this.formData).then(() => {
                        this.renderTable()
                        this.isEditing = false
                        this.clear()
                    })
                }
            })
        },
        clear() {
            Object.keys(this.formData).map(item => {
                this.formData[item] = ''
            })
            this.setInitData()
        }

    }
}
</script>
<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
